import { createContext, ReactNode } from 'react';

interface NotificationContextProps {
  showNotification: (
    type: 'success' | 'error' | 'warning',
    title: string,
    messageText: ReactNode,
  ) => void;
}

const NotificationContext = createContext<NotificationContextProps>({
  showNotification: () => {},
});

export default NotificationContext;
