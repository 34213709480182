export const theme = {
  token: {
    colorLink: '#34D399',
    colorLinkActive: '#2bbf85',
    colorLinkHover: '#4de0b3',
    colorPrimary: '#34D399',
    colorPrimaryActive: '#2bbf85',
    colorPrimaryBorder: '#2bbf85',
    colorPrimaryHover: '#4de0b3',
  },
};
